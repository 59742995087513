let jiancha=[
    {time:"2023-07-01",username:"实验室管理员",room:"土木实验室",content:"检查内容：物品是否归位，电源是否关闭，是否存在安全隐患",files:[{url:"http://pro9971bd04.pic13.ysjianzhan.cn/upload/g1ro.jpg"}]},
    {time:"2023-07-01",username:"实验室管理员",room:"土木实验室",content:"检查内容：物品是否归位，电源是否关闭，是否存在安全隐患",files:[{url:"http://pro9971bd04.pic13.ysjianzhan.cn/upload/g1ro.jpg"}]},
    {time:"2023-07-01",username:"实验室管理员",room:"土木实验室",content:"检查内容：物品是否归位，电源是否关闭，是否存在安全隐患",files:[{url:"http://pro9971bd04.pic13.ysjianzhan.cn/upload/g1ro.jpg"}]},
    {time:"2023-07-01",username:"实验室管理员",room:"土木实验室",content:"检查内容：物品是否归位，电源是否关闭，是否存在安全隐患",files:[{url:"http://pro9971bd04.pic13.ysjianzhan.cn/upload/g1ro.jpg"}]},
    {time:"2023-07-01",username:"实验室管理员",room:"土木实验室",content:"检查内容：物品是否归位，电源是否关闭，是否存在安全隐患",files:[{url:"http://pro9971bd04.pic13.ysjianzhan.cn/upload/g1ro.jpg"}]},
]

let yinhuan=[
    {time:"2023-07-01",username:"实验室管理员",room:"土木实验室",content:"电源未关，地上有积水",files:[{url:"http://pro9971bd04.pic13.ysjianzhan.cn/upload/g1ro.jpg"}]},
    {time:"2023-07-01",username:"实验室管理员",room:"土木实验室",content:"检电源未关，地上有积水",files:[{url:"http://pro9971bd04.pic13.ysjianzhan.cn/upload/g1ro.jpg"}]},
    {time:"2023-07-01",username:"实验室管理员",room:"土木实验室",content:"检电源未关，地上有积水",files:[{url:"http://pro9971bd04.pic13.ysjianzhan.cn/upload/g1ro.jpg"}]},
    {time:"2023-07-01",username:"实验室管理员",room:"土木实验室",content:"检电源未关，地上有积水",files:[{url:"http://pro9971bd04.pic13.ysjianzhan.cn/upload/g1ro.jpg"}]},
    {time:"2023-07-01",username:"实验室管理员",room:"土木实验室",content:"检电源未关，地上有积水",files:[{url:"http://pro9971bd04.pic13.ysjianzhan.cn/upload/g1ro.jpg"}]},
]


let jihua=[
    {time:"2023-07-01",username:"实验室管理员",room:"土木实验室",content:"计划内容：物品是否归位，电源是否关闭，是否存在安全隐患",files:[{url:"http://pro9971bd04.pic13.ysjianzhan.cn/upload/g1ro.jpg"}]},
    {time:"2023-07-01",username:"实验室管理员",room:"土木实验室",content:"计划内容：物品是否归位，电源是否关闭，是否存在安全隐患",files:[{url:"http://pro9971bd04.pic13.ysjianzhan.cn/upload/g1ro.jpg"}]},
    {time:"2023-07-01",username:"实验室管理员",room:"土木实验室",content:"计划内容：物品是否归位，电源是否关闭，是否存在安全隐患",files:[{url:"http://pro9971bd04.pic13.ysjianzhan.cn/upload/g1ro.jpg"}]},
    {time:"2023-07-01",username:"实验室管理员",room:"土木实验室",content:"计划内容：物品是否归位，电源是否关闭，是否存在安全隐患",files:[{url:"http://pro9971bd04.pic13.ysjianzhan.cn/upload/g1ro.jpg"}]},
    {time:"2023-07-01",username:"实验室管理员",room:"土木实验室",content:"计划内容：物品是否归位，电源是否关闭，是否存在安全隐患",files:[{url:"http://pro9971bd04.pic13.ysjianzhan.cn/upload/g1ro.jpg"}]},
]

let baojing=[
    {time:"2023-07-01",username:"实验室管理员",room:"土木实验室",content:"烟雾",files:[{url:"http://pro9971bd04.pic13.ysjianzhan.cn/upload/g1ro.jpg"}]},
    {time:"2023-07-01",username:"实验室管理员",room:"土木实验室",content:"火苗",files:[{url:"http://pro9971bd04.pic13.ysjianzhan.cn/upload/g1ro.jpg"}]},
    {time:"2023-07-01",username:"实验室管理员",room:"土木实验室",content:"课间使用手机",files:[{url:"http://pro9971bd04.pic13.ysjianzhan.cn/upload/g1ro.jpg"}]},
    {time:"2023-07-01",username:"实验室管理员",room:"土木实验室",content:"异常时间段闯入",files:[{url:"http://pro9971bd04.pic13.ysjianzhan.cn/upload/g1ro.jpg"}]},
    {time:"2023-07-01",username:"实验室管理员",room:"土木实验室",content:"课间使用手机",files:[{url:"http://pro9971bd04.pic13.ysjianzhan.cn/upload/g1ro.jpg"}]},
]


export  {
    jiancha,
    jihua,
    yinhuan,
    baojing
}