<template>
    <div class="cbox">
        <div class="headbar">
            <div style="float: right;">
                <div>
                    <el-input v-model="queryForm.keyword" size="small" placeholder="请输入关键字"
                        style="width:200px;margin-right:10px"></el-input>
                    <el-button size="small" type="primary" icon="el-icon-search" style="display:inline-block"
                        @click="">查询</el-button>
                    <!-- <el-button size="small" type="primary" icon="el-icon-plus" style="display:inline-block"
                        @click="addForm">添加</el-button> -->


                </div>

            </div>
            <div style="padding-top: 14px;">
                <el-breadcrumb>
                    <el-breadcrumb-item>实验室管理</el-breadcrumb-item>
                    <el-breadcrumb-item>实验室自动报警</el-breadcrumb-item>

                </el-breadcrumb>
            </div>

        </div>

        <div class="bcontent">

            <el-table :data="DataList" style="width: 100%;" stripe border size="small">
                <el-table-column type="index" label="编号" width="50"></el-table-column>


                <el-table-column prop="time" label="时间" width="180"></el-table-column>
                <el-table-column prop="content" label="报警" min-width="300">
                    <template slot-scope="scope">

                        {{ scope.row.content }}

                    </template>
                </el-table-column>

                <el-table-column prop="room" label="实验室" width="300"></el-table-column>

                

                <el-table-column prop="room_desc" label="现场图片" width="120">
                    <template slot-scope="scope">
                        <el-image fit="fill" v-for="(f, i) in scope.row.files" style="width:40px;height:40px" :src="f.url"
                            :preview-src-list="[f.url]"></el-image>


                    </template>
                </el-table-column>


                <el-table-column label="操作" width="180" fixed="right">
                    <template slot-scope="scope">
                        <!-- <el-tag size="mini" style="cursor: pointer" @click="editItem(scope.row)"><i
                                class="el-icon-edit"></i></el-tag> -->

                        <el-tag size="mini" type="warning" style="margin-left: 10px;cursor: pointer"
                            @click="deleteItem(scope.row)"><i class="el-icon-delete"></i>取消隐患</el-tag>

                    </template>
                </el-table-column>

            </el-table>

            <el-pagination class="cus_page" @size-change="handleSizeChange" @current-change="paginate"
                :current-page.sync="page.current_page" :page-sizes="[10, 50, 100]" :page-size="page.pre_page"
                layout="total,sizes, prev, pager, next" :total="page.count">
            </el-pagination>



            <div style="height: 20px;">



            </div>


        </div>


        <el-dialog title="编辑详细" :visible.sync="dialogVisible" custom-class="width_800 cus_dialog"
            :close-on-click-modal="false">
            <el-form :model="EditItem" label-width="120px">
                <el-row>
                    <el-col :span="20">
                        <el-form-item label="检查时间" prop="title">
                            <el-input v-model="EditItem.room_name" placeholder="请输入检查时间"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="20">
                        <el-form-item label="实验室" prop="title">
                            <el-input v-model="EditItem.address" placeholder="请输入实验室"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="20">
                        <el-form-item label="检查内容" prop="title">

                            <el-input type="textarea" v-model="EditItem.room_desc" placeholder="请输入检查内容"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="20">
                        <el-form-item label="附件" prop="title">

                            <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/"
                                :show-file-list="false" :on-success="handleAvatarSuccess"
                                :before-upload="beforeAvatarUpload">
                                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>


                <el-row style="text-align:right">
                    <el-col :span="20">
                        <el-button type="primary" icon="el-icon-check" style="display:inline-block"
                            @click="saveForm">保存</el-button>
                    </el-col>

                </el-row>

            </el-form>

        </el-dialog>



    </div>
</template>

<script>
let mockData = require('./datatest.js')

export default {
    components: {

    },
    data() {
        return {
            queryForm: {
                keyword: "",
                ktype: "room_name"
            },
            dialogVisible: false,
            showUserDialog: false,
            vrDialog: false,
            jkDialog: false,
            DataList: mockData.baojing,
            page: {
                current_page: 1,
                pre_page: 20,
                count: 0,
                pageSizes: [10, 50, 100]
            },

            EditItem: {
                room_name: "",
                address: "",
                room_desc: "",
                vr_url: ""
            }


        };
    },

    mounted() {

        // this.$http.post("/api/get_teacher_info", {

        // }).then(res => {
        //     this.userinfo = res.data
        //     this.getList()
        // })

    },
    methods: {

        getList() {
            this.$http.post("/api/t_sch_sysrooms_list", {
                page: this.page.current_page,
                pagesize: this.page.per_page,
                ktype: this.queryForm.ktype,
                keyword: this.queryForm.keyword

            }).then(res => {
                this.DataList = res.data.data

                this.page = res.data.page
            })
        },
        handleSizeChange(val) {
            this.page.current_page = 1
            this.page.pre_page = val
            this.getList();
        },
        paginate(val) {
            this.page.current_page = val
            this.getList();
        },
        addForm() {
            this.EditItem = {
                room_name: "",
                address: "",
                room_desc: "",
                vr_url: ""
            }
            this.dialogVisible = true
        },
        saveForm() {
            // this.$http.post("/api/t_sch_sysrooms_edit", this.EditItem).then(res => {
            //     this.$message.success("保存成功")
            //     this.getList()
            //     this.dialogVisible = false
            // })
        },
        editItem(e) {
            this.EditItem = e;
            this.dialogVisible = true
        },
        deleteItem(e) {
            this.$confirm('是否确认删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                
            })
        },
        getUser(e) {
            console.log('----------------')
            console.log(e)
            if (e && e.length > 0) {
                this.EditItem.teacher_id = e[0].id
                this.EditItem.username = e[0].username
            }
        },
        showDeptUserDialog() {
            //alert(111)
            this.showUserDialog = true
        },
        viewVR(row) {
            this.EditItem = row;
            this.vrDialog = true
        },
        viewJK(row) {
            this.EditItem = row;
            this.jkDialog = true
        },
    },
}
</script>

<style scoped="scoped">
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
